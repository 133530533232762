body {
    background: #ebf2f5;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #cfe7e3, #f4f8fa);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #cfd9e7, #f4f8fa); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */   
}
.App {
    display: flex;
    justify-content: space-around;

    .menu-container {
        position: fixed;
        z-index: 3;
        top: 0;
        left: 0;
        width: 20%;
        height: 100vh;
        background-color: mediumaquamarine;
        // border: solid 2px red;

        .menu-bars {
            font-size: 2.6rem;
            color: white;
            margin: 10px 0 0 12px;
            padding: 6px;
            // border: solid 2px yellow;
            &:hover {
                cursor: pointer;
            }
        }
        .menu-times {
            font-size: 2.6rem;
            color: white;
            margin: 10px 0 0 12px;
            padding: 6px;
            // border: solid 2px yellow;
            &:hover {
                cursor: pointer;
            }
        }

        ul {
            opacity: 0;
            list-style: none;
            padding: 10%;
            margin: 0%;

            li {
                padding: 8% 0 8%;
                margin: 0;
                &:hover {
                    cursor: pointer;
                }
                
                a {
                    text-decoration: none;
                    color: white;
                    font-size: 2rem;
                }
            }

            i {
                font-size: 2.4rem;
                color: white;
                padding: 5% 3%;
                margin: 24% 0;
                // border: solid 2px tomato;
            }
        }
    }

    .cover {
        position: fixed;
        z-index: 2;
        top: 0;
        left: 0;
        width: 20%;
        height: 100vh;
        background-color: mediumaquamarine;
        // border: solid 2px green;

    }

    .projects-container {
        width: 100%;
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 4% 0 0 10%;
        // border: solid 2px purple;

        .project-card {
            background: rgb(236, 234, 234);
            width: 18%;
            height: auto;
            padding: 1%;
            border-radius: 5px;
            transition: transform .3s, border .6s, box-shadow .25s, width .25s, background .2s;
            border: 3px solid lightsteelblue;
            &:hover {
                cursor: pointer;
                transform: scale(1.04);
                box-shadow: 10px 8px 5px rgba(28, 38, 73, 0.225);
            }
            a {
                img {
                    width: 100%;
                    height: 160px;
                    box-shadow: 3px 1px 5px rgba(28, 38, 73, 0.225);
                }
            }
            .project-name {
                margin: 5% 1%;
                font-size: 1.3rem;
                width: 100%;
                text-align: center;
            }
            .tech {
                padding: 0 3%;
                h4 {
                    text-align: left;
                }
                .tech-list {
                    text-align: left;
                }
            }
        }
        .selected-card {
            background: whitesmoke;
            width: 21%;
            height: auto;
            padding: 1%;
            border-radius: 5px;
            transition: transform .3s, border .6s, box-shadow .25s, width .25s, background .2s;
            border: 3px solid slateblue;
            &:hover {
                cursor: pointer;
                transform: scale(1.04);
                box-shadow: 10px 8px 5px rgba(28, 38, 73, 0.225);
            }
            a {
                img {
                    width: 100%;
                    height: 160px;
                    box-shadow: 3px 1px 5px rgba(28, 38, 73, 0.225);
                }
            }
            .project-name {
                margin: 5% 1%;
                font-size: 1.4rem;
                width: 100%;
                text-align: center;
            }
            .tech {
                text-align: center;
                h4 {
                    text-align: center;
                    margin: 6% 0 3%;
                }
                .tech-list {
                    text-align: left;
                    border: green;
                    margin: 0;
                }
            }
        }
    }

    .about-container {
        width: 80%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        .header {
            width: 100%;
            font-size: 1.5rem;
            text-align: center;
            padding: 0;
            margin: 0 10% 2% 0;
            color: red;
            // border: 2px solid grey;
            h1 {
                width: 100%;
                margin: 0;
                padding: 0;
            }
        }

        .aldair-persona {
            width: 385px;
            height: 545px;
            top: 10%;
            left: 71%;
            position: absolute;
            z-index: 1;
        }

        .container {
            width: 86%;
            height: auto;
            box-sizing: border-box;
            padding: 1% 2%;
            border-radius: 9px;
            margin: 1% 0 0;
            z-index: 0;
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.4);
            background:  rgb(228, 228, 228);
            .speech-container {
                .speaker {
                    font-size: 1.8rem;
                    margin: 0;
                    padding: 0;
                }
                .speech {
                    padding: 1% 0;
                    margin: 0;
                    color: purple;
                }
            }
        }

        .tech-container {
            width: 70%;
            padding: 8% 0 0;
            display: flex;
            justify-content: space-around;
            font-size: 5.6rem;
            .fa-python {
                color: rgb(114, 135, 156);
                text-shadow: 3px 2px rgba(0, 0, 0, 0.3);
            }
            .fa-html5 {
                color: rgb(238, 33, 6);
                text-shadow: 2px 2px rgba(0, 0, 0, 0.3);
            }
            .fa-css3-alt {
                color: rgb(52, 141, 243);
                text-shadow: 2px 2px rgba(0, 0, 0, 0.3);
            }
            .fa-js-square {
                color: rgb(253, 192, 23);
                text-shadow: 3px 2px rgba(0, 0, 0, 0.3);
            }
            .fa-react {
                color: rgb(57, 119, 253);
                text-shadow: 2px 2px rgba(0, 0, 0, 0.3);
            }
            .fa-node {
                color: rgb(29, 189, 37);
                text-shadow: 2px 0px rgba(0, 0, 0, 0.3);
            }
        }
    }
}